
import { Component, Mixins } from 'vue-property-decorator'
import { StoreMixin } from '@/mixins'
import { FooterMenuEvent, FooterMenuTab } from '@/utilities'
import { footerMenuEventBus } from '@/main'

@Component
export default class AppFooterMenu extends Mixins(StoreMixin) {
  constructor() {
    super()
  }

  get activatedTab(): number {
    const footerMenuValueRecord = {
      [FooterMenuTab.Top]: 0,
      [FooterMenuTab.RouteSearch]: 1,
      [FooterMenuTab.MyTicket]: 2,
    }
    return footerMenuValueRecord[this.currentFooterTab]
  }

  destroyed() {
    footerMenuEventBus.$off(FooterMenuEvent.ChangeTab)
  }

  get showFooter() {
    // MLC-0900
    if (this.systemProperties.maintenance.isShow) {
      return false
    }

    if (!this.isUserAuthenticated) {
      return false
    }

    if (this.userProfile.isNewUser) {
      return false
    }

    const isUserRestricted =
        this.isUserAuthenticated &&
        this.isUserPermissionDenied &&
        this.systemProperties.mobile.loginRestrictions

    // MLO-0900
    if (isUserRestricted) {
      return false
    }

    return true
  }

  handleTopClick(): void {
    if (this.currentFooterTab === FooterMenuTab.Top) {
      this.restartTopTabToDefaultProcess()
    } else {
      this.switchToTopTab()
    }
  }

  handleRouteSearchClick(): void {
    if (this.currentFooterTab === FooterMenuTab.RouteSearch) {
      this.restartRouteSearchTabToDefaultProcess()
    } else {
      this.switchToRouteSearchTab()
    }
  }

  handleMyTicketClick(): void {
    if (this.currentFooterTab === FooterMenuTab.MyTicket) {
      this.restartMyTicketTabToDefaultProcess()
    } else {
      this.switchToMyTicketTab()
    }
  }
}
