
import firebase from 'firebase/app'
import 'firebase/auth'
import { Component, Mixins } from 'vue-property-decorator'
import { ApiService, FirebaseService, FirestoreService } from '@/services'
import { AppBase, StoreMixin } from '@/mixins'
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppFooterMenu from '@/components/AppFooterMenu.vue'
import AppLoader from '@/components/AppLoader.vue'
import AppDialog from '@/components/AppDialog.vue'
import MaintenanceScreen from '@/views/MLC_0900.vue'
import SplashScreen from '@/views/MLO_0800.vue'
import LoginProcess from '@/components/LoginProcess.vue'
import WithdrawScreen from '@/views/MTP_0400.vue'
import SelectLanguage from '@/components/SelectLanguage.vue'
import { D10, ScreenId } from '@/utilities'
import { FetchDataMixin } from '@/mixins/fetch-data'

@Component({
  components: {
    AppFooterMenu,
    SelectLanguage,
    AppHeader,
    AppFooter,
    AppLoader,
    AppDialog,
    MaintenanceScreen,
    LoginProcess,
    SplashScreen,
    WithdrawScreen,
  },
})
export default class App extends Mixins(AppBase, StoreMixin, FetchDataMixin) {
  isFirestoreFetched: boolean = false
  isLoadingFirestore: boolean = false

  constructor() {
    super()
  }

  created() {
    this.init()
    this.$nextTick(() => {
      this.$customApp.customApp()
    })

    window.addEventListener(
      'beforeunload',
      () => {
        if (this.userProfile.isNewUser) {
          // logout newly created user
          // handle for case when create new account firebase will login user automatically
          this.logout()
        }
      },
      false
    )

    // Block Back Event
    history.pushState(null, '', window.location.pathname)
    window.addEventListener('popstate', this.onPopstate, false)
  }

  destroyed() {
    window.removeEventListener('popstate', this.onPopstate, false)
  }

  async init(): Promise<void> {
    try {
      this.isLoadingFirestore = true
      await this.initAuth()
      await this.fetchMainData()
      await FirebaseService.getRedirectLoginResult()
      this.isLoadingFirestore = false
    } catch (error) {
      this.$dialog.showError(ScreenId.MLC_0100, error as string)
      this.isLoadingFirestore = false
    }
  }

  async initAuth(): Promise<void> {
    return new Promise((resolve) => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const emailPromise = this.synchronizeProfileEmail(user.email)
          const namePromise = this.synchronizeProfileName(user.displayName)
          const [email, name] = await Promise.all([emailPromise, namePromise])

          const { token, signInProvider } = await user.getIdTokenResult(true)
          const profile = {
            name,
            email,
            signInProvider,
            emailVerified: user.emailVerified,
            uid: user.uid,
            idToken: token,
          }

          if (!profile.emailVerified) {
            await FirebaseService.sendEmailVerification().catch(() => {})
          }

          this.updateUserProfile({ ...profile, ...this.userProfile })

          await this.initFirestoreOnce()

          if (this.systemProperties.mobile.loginRestrictions) {
            const hasPermission = await this.checkUserPermission(email)
            if (hasPermission) {
              // api signIn when called successfully will create a new sessionID on firestore
              const response = await ApiService.signIn()
              this.presetUserSessionID(response.data.sessionID)
              await this.getUserData()
              this.setPermissionDenied(false)
            } else {
              this.setPermissionDenied(true)
            }
          } else {
            const response = await ApiService.signIn()
            this.presetUserSessionID(response.data.sessionID)
            await this.getUserData()
          }

          this.setUserAuth()

          // fix toggle loading indicator async issue while validate auth state between FirebaseService.login and onAuthStateChange
          this.toggleLoading(false)
        } else {
          this.clearUserSession()
          await this.initFirestoreOnce()
        }
        // scroll to top page
        window.scroll(0, 0)

        resolve()
      })
    })
  }

  async initFirestoreOnce(): Promise<any> {
    if (!this.isFirestoreFetched) {
      this.isFirestoreFetched = true
      await this.initFirestore()
    }
  }

  async initFirestore(): Promise<any> {
    return Promise.all([
      this.getSystemEnvironment(),
      this.getSystemProperties(),
      this.getSystemRouteSearch(),
    ])
  }

  async synchronizeProfileName(name: string | null): Promise<string> {
    let result = name
    firebase.auth()!.currentUser!.providerData.forEach((providerProfile) => {
      if (providerProfile) {
        const { displayName } = providerProfile
        if (displayName != name && displayName != null) {
          firebase.auth()!.currentUser!.updateProfile({ displayName: displayName })
          name = providerProfile!.displayName
        }
      }
    })
    return result || ''
  }

  async synchronizeProfileEmail(profileEmail: string | null): Promise<string> {
    let result = profileEmail
    firebase.auth()!.currentUser!.providerData.forEach((providerProfile) => {
      if (providerProfile) {
        const { email: providerEmail } = providerProfile
        if (providerEmail && providerEmail !== profileEmail) {
          firebase.auth()!.currentUser!.updateEmail(providerEmail)
          result = providerEmail
        }
      }
    })
    return result || ''
  }

  async checkUserPermission(email: string): Promise<boolean> {
    try {
      const doc = await FirestoreService.getUserPermission(email)
      if (!doc.exists) {
        return false
      }

      return true
    } catch (error) {
      return false
    }
  }

  clearUserSession() {
    // handle special case when user got removed on firebase
    // removed user auth state
    this.$store.commit('auth/PURGE_AUTH')
    this.setMainDataFetched(false)
  }

  async fetchMainData() {
    if (this.isUserAuthenticated) {
      await this.setMainDataFetched(true)
      await this.fetchAppMainData()
    }
  }

  onPopstate(e): void {
    let mlo0800 = document.querySelector('#mlo-0800') // 起動時に表示する画面

    if (!mlo0800) {
      this.$dialog.showError(ScreenId.MLC_0100, D10.ForceBackkey)
    }
    history.pushState(null, '', window.location.pathname)
  }
}
