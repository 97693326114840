
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { StorageService } from '@/services'
import { LanguageCode } from '@/i18n'
import resources from '@/i18n/ja.json'
import { languageEventBus } from '@/main'
import { LanguageEvent } from '@/utilities'

interface ILanguageResource {
  text: string;
  value: LanguageCode
}

@Component
export default class SelectLanguage extends Vue {
  currentLanguage: ILanguageResource = {} as ILanguageResource

  constructor() {
    super()
  }

  created() {
    this.currentLanguage = this.getCurrentLanguage()
  }

  get allowedLanguages(): ILanguageResource[] {
    const availableLanguages = resources.Languages as ILanguageResource[]
    return availableLanguages.filter(l => this.$store.getters['system/properties'].language[l.value])
  }

  changeCurrentLanguage(langCode: LanguageCode): void {
    this.currentLanguage = {
      text: this.getLanguageTextBy(langCode),
      value: langCode
    }
    this.changeAndSaveCurrentLanguage(langCode)
  }

  private getCurrentLanguage(): any {
    const cachedLanguage = StorageService.getCurrentLanguage()
    if (!cachedLanguage) {
      return { text: '日本語', value: LanguageCode.Ja }
    }

    const result = this.checkCachedLanguageAllowed(cachedLanguage)
    if (!result) {
      return { text: 'English', value: LanguageCode.En }
    }

    return { ...result }
  }

  private checkCachedLanguageAllowed(langCode: string): any {
    return this.allowedLanguages.find(language => language.value === langCode)
  }

  private changeAndSaveCurrentLanguage(lang: LanguageCode): void {
    this.$i18n.locale = lang
    languageEventBus.$emit(LanguageEvent.LanguageChanged, lang)
    StorageService.setCurrentLanguage(lang)
  }

  private getLanguageTextBy(langCode: LanguageCode): string {
    const langResource = this.allowedLanguages.find(l => l.value === langCode)
    return langResource!.text
  }
}
