
import Vue from 'vue'
import Component from 'vue-class-component'
import { ScreenId } from '@/utilities'
import { Mixins } from 'vue-property-decorator'
import { ProcessMixin } from '../mixins'

@Component({
  components: {
    [ScreenId.MLO_0000]: () => import('@/views/MLO_0000.vue'),
    [ScreenId.MLO_0100]: () => import('@/views/MLO_0100.vue'),
    [ScreenId.MLO_0200]: () => import('@/views/MLO_0200.vue'),
    [ScreenId.MLO_0210]: () => import('@/views/MLO_0210.vue'),
  },
})
export default class LoginProcess extends Mixins(ProcessMixin) {
  email: string = ''

  constructor() {
    super()
    this.currentScreen = ScreenId.MLO_0000
  }
}
