
import { Component, Mixins } from 'vue-property-decorator'
import { AppBase } from '@/mixins'
import { D10, D11, ScreenId } from '@/utilities'
import Dialog from '@/plugins/dialog'

@Component({
  components: {
    [ScreenId.MLC_0100]: () => import('@/views/MLC_0100.vue'),
    [ScreenId.MLC_0110]: () => import('@/views/MLC_0110.vue'),
    [ScreenId.MLC_0120]: () => import('@/views/MLC_0120.vue'),
  },
})
export default class AppDialog extends Mixins(AppBase) {
  private currentDialog: ScreenId = ScreenId.MLC_0100
  private visible!: boolean
  private title!: String
  private message!: String
  private isConfirm!: boolean
  private confirm!: (data?: any) => void
  private cancel!: (data?: any) => void

  constructor() {
    super()
    this.isConfirm = false
    this.visible = false
    this.title = ''
    this.message = ''
  }

  beforeMount() {
    this.onDialogEventBus()
  }

  private onDialogEventBus() {
    Dialog.eventBus.$on('showDoubleLoginError', this.showDoubleLoginError)
    Dialog.eventBus.$on('showError', this.showDialogError)
    Dialog.eventBus.$on('showConfirm', this.showDialogConfirm)
    Dialog.eventBus.$on('showApiError', this.showApiError)
    Dialog.eventBus.$on('showBikeShareMessage', this.showBikeShareMessage)
    Dialog.eventBus.$on('hideDialog', this.hideDialog)
    Dialog.eventBus.$on('dialogId', this.getDialogComponent)
  }

  private clearDialogEventBus() {
    Dialog.eventBus.$off('showDoubleLoginError', this.showDoubleLoginError)
    Dialog.eventBus.$off('showError', this.showDialogError)
    Dialog.eventBus.$off('showConfirm', this.showDialogConfirm)
    Dialog.eventBus.$off('showApiError', this.showApiError)
    Dialog.eventBus.$off('showBikeShareMessage', this.showBikeShareMessage)
    Dialog.eventBus.$off('hideDialog', this.hideDialog)
    Dialog.eventBus.$off('dialogId', this.getDialogComponent)
  }

  private getDialogComponent(dialogId: ScreenId) {
    this.currentDialog = dialogId
  }

  private showDoubleLoginError(doConfirm: () => void) {
    const title = `D10.${D10.ForceLogout}.message1`
    const message = `D10.${D10.ForceLogout}.message2`

    this.isConfirm = false
    this.visible = true
    this.title = this.translate(title)
    this.message = this.translate(message)

    // prevent other dialog to overlap
    // double login error dialog is critical and has highest priority
    this.clearDialogEventBus()

    this.confirm = () => {
      doConfirm()
      this.onDialogEventBus()
    }
  }

  private showDialogError(code: string, confirm: () => void) {
    let title = 'D11.ERROR.message1'
    let message = 'D11.ERROR.message2'

    if (code.includes('FIREBASE')) {
      title = `${code}.message1`
      message = `${code}.message2`
    } else if (code.includes('d10')) {
      title = `D10.${code}.message1`
      message = `D10.${code}.message2`
    }

    //set type, visible, title, message and confirm action of error dialog
    this.isConfirm = false
    this.visible = true
    this.title = this.translate(title)
    this.message = this.translate(message)
    this.confirm = confirm
  }

  private showDialogConfirm(code: string, confirm: () => void, cancel: () => void) {
    //get title code of dialog
    const title = (code || '').split('.').join('.message1.')

    //get message code of dialog
    const message = (code || '').split('.').join('.message2.')

    //set type, visible, title, message, confirm action, cancel action of confirm dialog
    this.isConfirm = true
    this.visible = true
    this.title = this.translate(title)
    this.message = this.translate(message)
    this.confirm = confirm
    this.cancel = cancel
  }

  private showApiError(code: string, confirm: () => void) {
    if (code === D11.RequestErrorStartLimit) {
      this.title = this.getContentByCurrentLanguage(
        this.$store.getters['system/properties'].startLimit.startLimitDialog.title
      )
      this.message = this.getContentByCurrentLanguage(
        this.$store.getters['system/properties'].startLimit.startLimitDialog.message
      )
    } else {
      this.title = this.translate(`D11.${code}.message1`)
      this.message = this.translate(`D11.${code}.message2`)
    }

    //set type, visible, title, message, confirm action, cancel action of confirm dialog
    this.isConfirm = false
    this.visible = true
    this.confirm = confirm
  }

  private showBikeShareMessage(code: string, confirm: () => void) {
    //set type, visible, title, message, confirm action, cancel action of confirm dialog
    this.title = this.translate(`D12.${code}.message1`)
    this.message = this.translate(`D12.${code}.message2`)
    this.isConfirm = false
    this.visible = true
    this.confirm = confirm
  }

  private handleConfirm() {
    if (typeof this.confirm === 'function') {
      this.confirm()
    }
    this.hideDialog()
  }

  private handleCancel() {
    if (typeof this.cancel === 'function') {
      this.confirm()
    }
    this.hideDialog()
  }

  private hideDialog() {
    this.visible = false
    this.title = ''
    this.message = ''
    this.confirm = () => {}
    this.cancel = () => {}
  }
}
