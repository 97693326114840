
import { Mixins, Component } from 'vue-property-decorator'
import { StoreMixin, AppBase } from '@/mixins'

@Component
export default class AppFooter extends Mixins(AppBase, StoreMixin) {
  constructor() {
    super()
  }

  get externalLink() {
    return this.$store.getters['system/link']
  }

  get showFooter() {
    // MLC-0900
    if (this.systemProperties.maintenance.isShow) {
      return false
    }
    // MTP-0400
    if (this.isWithdrawUser) {
      return false
    }
    // MLO-0110
    if (!this.userProfile.emailVerified) {
      return true
    }
    const isUserRestricted =
      this.isUserAuthenticated &&
      this.isUserPermissionDenied &&
      this.systemProperties.mobile.loginRestrictions

    // MLO-0900
    if (isUserRestricted) {
      return false
    }

    return true
  }

  handleFAQClick(): void {
    // https://developers.google.com/web/tools/lighthouse/audits/noopener
    const newWindow = window.open(this.getContentByCurrentLanguage(this.externalLink.faq))
    if (newWindow) {
      newWindow.opener = null
    }
  }

  handlePrivacyClick(): void {
    // https://developers.google.com/web/tools/lighthouse/audits/noopener
    const newWindow = window.open(this.getContentByCurrentLanguage(this.externalLink.privacy))
    if (newWindow) {
      newWindow.opener = null
    }
  }

  handleTermClick(): void {
    // https://developers.google.com/web/tools/lighthouse/audits/noopener
    const newWindow = window.open(this.getContentByCurrentLanguage(this.externalLink.term))
    if (newWindow) {
      newWindow.opener = null
    }
  }

  handletransactionClick(): void {
    // https://developers.google.com/web/tools/lighthouse/audits/noopener
    const newWindow = window.open(
      this.getContentByCurrentLanguage(this.externalLink.transaction)
    )
    if (newWindow) {
      newWindow.opener = null
    }
  }
}
