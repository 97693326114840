import firebase from 'firebase/app'

import store from '@/store'
import { httpClient, httpError } from './axios'
import { StorageService } from '@/services/storage'
import i18n, { LanguageCode } from '@/i18n'
import { IMapIdenfitifer, IMapLanguage } from '@/models/common'
import { IUpdateGMOMember } from '@/models/TicketMaster'
import { IGMOMember } from '@/models/TicketMaster'
import { ILocationSuggestInformation, IRouteSearchRequest } from '@/custommodels'
import { IPortDetail } from '@/models/DocomoBikeSharePortMaster'
import axios from 'axios'
import { IRouteInformation } from '@/custommodels/IRouteSearchCustom'
import { ITicketOnSale } from '@/custommodels/ITicketCustom'

export class ApiService {
  private static get apiUrl() {
    return store.getters['system/environment'].backend.api
  }

  private static get apiUrlMobile() {
    return store.getters['system/environment'].mobile.api
  }

  public static async getTicketsOnSale(): Promise<{ data: {
      ticketInfoList: ITicketOnSale[];
      errorCode: string } }> {
    const idToken = await this.getIdToken()
    return await httpClient.get(this.apiUrlMobile.getTicketsOnSale,{
      headers: {
        'Content-Type': 'application/json',
        idToken
      }
    })
  }

  public static async addQRTicket(codeID: string): Promise<{ data: { errorCode: string } }> {
    const idToken = await this.getIdToken()
    return await axios.post(this.apiUrl.addQRTicket, { codeID }, {
      headers: {
        'Content-Type': 'application/json',
        idToken,
      }
    })
  }

  public static async execGMOSettlement(data: any): Promise<{ data: { errorCode: string, salesID: string } }> {
    const idToken = await this.getIdToken()
    return await axios.post(this.apiUrl.execGMOSettlement, data, {
      headers: {
        'Content-Type': 'application/json',
        idToken,
      }
    })
  }

  public static async startUsing(data: { orderID: string; purchasedTicketID: string }) {
    const idToken = await this.getIdToken()
    return httpClient.post(this.apiUrl.startUsing, data, {
      headers: { idToken },
    })
  }

  public static async confirmStartUsing(data: { orderID: string; purchasedTicketID: string }) {
    const idToken = await this.getIdToken()
    return httpClient.post(this.apiUrl.confirmStartUsing, data, {
      headers: { idToken },
    })
  }

  public static async writeGatePassInfoForBrowser(
    idToken: string,
    data: {
      url: string
      orderID: string
      purchasedTicketID: string
    }
  ): Promise<{ data: { errorCode: string; gateID: string } }> {
    return httpClient.post(this.apiUrl.writeGatePassInfoForBrowser, data, {
      headers: { idToken },
    })
  }

  public static async createQRCode(data: {
    orderID: string
    purchasedTicketID: string
  }): Promise<{ data: { errorCode: string; qrCode: string; expirationDate: string } }> {
    const idToken = await this.getIdToken()
    return httpClient.post(this.apiUrl.createQRCode, data, {
      headers: { idToken },
    })
  }

  public static async getStartableTickets(data: {
    facilityID: string
  }): Promise<{
    data: { errorCode: string; tickets: [{ orderID: string; purchasedTicketID: string }] }
  }> {
    const idToken = await this.getIdToken()
    return httpClient.post(this.apiUrl.getStartableTickets, data, {
      headers: { idToken },
    })
  }

  public static async startUsingWithFacilityID(data: {
    facilityID: string
    orderID: string
    purchasedTicketID: string
  }): Promise<{
    data: { errorCode: string; tickets: [{ orderID: string; purchasedTicketID: string }] }
  }> {
    const idToken = await this.getIdToken()
    return httpClient.post(this.apiUrl.startUsingWithFacilityID, data, {
      headers: { idToken },
    })
  }

  public static async purchaseTicket(data: {
    lang: string
    amount: number
    origin: string
    main: {
      ticketID: string
      numbers: IMapIdenfitifer
    }
    options: {
      ticketID: string
      numbers: IMapIdenfitifer
    }[]
  }): Promise<{
    data: { errorCode: string; url: string }
  }> {
    let api = store.getters['system/environment'].backend.api
    let token = await this.getIdToken()
    return await httpClient.post(api.getPurchaseURL, data, {
      headers: {
        idToken: token,
      },
    })
  }

  public static async editCreditCard(): Promise<{
    data: {
      errorCode: string
      url: string
    }
  }> {
    let api = store.getters['system/environment'].backend.api
    let token = await this.getIdToken()
    let params = {
      lang: StorageService.getCurrentLanguage() || LanguageCode.Ja,
      origin: location.origin,
    }
    return await httpClient.post(api.getEditCreditURL, params, {
      headers: {
        idToken: token,
      },
    })
  }

  public static async purchaseOptionTicket(data: {
    lang: string
    orderID: string
    origin: string
    amount: number
    options: {
      ticketID: string
      numbers: IMapIdenfitifer
    }[]
  }): Promise<{
    data: {
      errorCode: string
      url: string
    }
  }> {
    let api = store.getters['system/environment'].backend.api
    let token = await this.getIdToken()
    return await httpClient.post(api.getOptionPurchaseURL, data, {
      headers: {
        idToken: token,
      },
    })
  }

  public static async getMemberID(): Promise<{
    data: { errorCode: string; memberID: string }
  }> {
    let api = store.getters['system/environment'].backend.api
    let token = await this.getIdToken()
    return await httpClient.get(api.getMemberID, {
      headers: {
        idToken: token,
      },
    })
  }

  public static async signIn(): Promise<{
    data: { errorCode: string; sessionID: string }
  }> {
    let api = store.getters['system/environment'].backend.api
    let token = await this.getIdToken()
    return await httpClient.get(api.signIn, {
      headers: {
        idToken: token,
      },
    })
  }

  public static async requestRefund(data: { orderID: string, purchasedTicketID: string }): Promise<{ data: { errorCode: string } }> {
    const idToken = await this.getIdToken()
    return await axios.post(this.apiUrl.requestRefund, data, {
      headers: {
        'Content-Type': 'application/json',
        idToken,
      }
    })
  }

  public static getIdToken(): Promise<string> {
    let user = firebase.auth().currentUser
    return user!
      .getIdToken(true)
      .then((token) => {
        return token
      })
      .catch((error) => {
        return httpError(error)
      })
  }

  public static async getGatePassTickets(data: {
    gateID: string
  }): Promise<{
    data: {
      errorCode: string
      tickets: {
        orderID: string
        purchasedTicketID: string
      }[]
      status: string
    }
  }> {
    let api = store.getters['system/environment'].backend.api
    let token = await this.getIdToken()
    return await httpClient.post(api.getGatePassTickets, data, {
      headers: {
        idToken: token,
      },
    })
  }

  public static async writeGatePassInfo(data: {
    gateID: string
    orderID: string
    purchasedTicketID: string
  }): Promise<{
    data: {
      errorCode: string
    }
  }> {
    let api = store.getters['system/environment'].backend.api
    let token = await this.getIdToken()
    return await httpClient.post(api.writeGatePassInfo, data, {
      headers: {
        idToken: token,
      },
    })
  }

  public static async getGMOMember(): Promise<{
    data: IGMOMember
  }> {
    let api = store.getters['system/environment'].backend.api
    let token = await this.getIdToken()
    return await httpClient.get(api.getGMOMember, {
      headers: {
        idToken: token,
      },
    })
  }

  public static async updateGMOMember(data: IUpdateGMOMember): Promise<{ data: { errorCode: string } }> {
    const idToken = await this.getIdToken()
    return await axios.post(this.apiUrl.updateGMOMember, data, {
      headers: {
        'Content-Type': 'application/json',
        idToken,
      }
    })
  }

  public static async getTermsText(data: {
    entID: string
    areaID: number
  }): Promise<{ data: { errorCode: string; terms: IMapLanguage } }> {
    let api = store.getters['system/environment'].maas.api
    let token = await this.getIdToken()
    return await httpClient.post(api.getTermsText, data, {
      headers: {
        idToken: token,
      },
    })
  }

  public static async getRouteSuggests(
    keyword: string
  ): Promise<{
    data: {
      errorCode: string
      suggestList: ILocationSuggestInformation[]
    }
  }> {
    const maasApi = store.getters['system/environment'].maas.api
    const idToken = await this.getIdToken()
    return await axios.post(maasApi.getRouteSuggests,
      { keyword, lang: i18n.locale }, {
      headers: {
        'Content-Type': 'application/json',
        idToken,
      }
    })
  }

  public static async getPortDetail(data: {
    orderID: string
    purchasedTicketID: string
    portID: string
  }): Promise<{ data: IPortDetail }> {
    let api = store.getters['system/environment'].maas.api
    let token = await this.getIdToken()
    return await httpClient.post(api.getPortDetail, data, {
      headers: {
        idToken: token,
      },
    })
  }

  public static async reserveCycle(data: {
    portID: string
    orderID: string
    cycleName: string
    purchasedTicketID: string
  }): Promise<{ data: { errorCode: string } }> {
    let api = store.getters['system/environment'].maas.api
    let token = await this.getIdToken()
    return await httpClient.post(api.reserveCycle, data, {
      headers: {
        idToken: token,
      },
    })
  }

  public static async cancelReserveCycle(data: {
    orderID: string
    purchasedTicketID: string
  }): Promise<{ data: { errorCode: string } }> {
    let api = store.getters['system/environment'].maas.api
    let token = await this.getIdToken()
    return await httpClient.post(api.cancelReserveCycle, data, {
      headers: {
        idToken: token,
      },
    })
  }

  public static async unlockCycle(data: {
    orderID: string
    purchasedTicketID: string
  }) {
    let api = store.getters['system/environment'].docomo.api
    let token = await this.getIdToken()
    return await httpClient.post(api.unlockCycle, data, {
      headers: {
        idToken: token,
      },
    })
  }

  public static async getRouteResults(
    data: IRouteSearchRequest
  ): Promise<{
    data: {
      errorCode: string
      routeList: IRouteInformation[]
    }
  }> {
    let api = store.getters['system/environment'].maas.api
    let token = await this.getIdToken()
    let lang = i18n.locale
    return await axios.post(
      api.getRouteResults,
      { ...data, lang: lang },
      {
        headers: {
          'Content-Type': 'application/json',
          idToken: token,
        },
      }
    )
  }

  public static async writeLog(
    log: string
  ): Promise<{
  }> {
    let api = store.getters['system/environment'].backend.api
    let token = await this.getIdToken()
    return await axios.post(
      api.writeLog,
      { log },
      {
        headers: {
          'Content-Type': 'application/json',
          idToken: token,
        }
      },
    )
  }
  public static async confirmWithdrawUser(): Promise<{
    data: { errorCode: string; isExistsTickets: boolean }
  }> { 
    return await httpClient.get(this.apiUrlMobile.confirmWithdrawUser, {
      headers: { idToken: await this.getIdToken() },
    })
   }
  public static async withdrawUser(data: {lang: string}): Promise<{
    data: { errorCode: string }
  }> {
    return await httpClient.post(this.apiUrlMobile.withdrawUser,data,{
      headers: { idToken: await this.getIdToken() },
    })
  }
}



