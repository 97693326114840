
import { Component, Vue } from 'vue-property-decorator'
import customizeStyle from '../../../customize.json'

/**
 * 画面仕様書
 *
 * 機能名　  : ログイン機能
 * 画面ＩＤ  : MLO-0800
 * 画面名　  : スプラッシュ画面
 * 概要　　  : 起動時に表示する画面
 */
@Component
export default class MLO_0800 extends Vue {
  primary: object = customizeStyle.header.primary

  constructor() {
    super()
  }
}
