
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { filePath } from '@/utilities/file'
import { AppBase, StoreMixin } from '@/mixins'

/**
 * 画面仕様書
 *
 * 機能名　  : 共通機能
 * 画面ＩＤ  : MLC-0900
 * 画面名　  : （画面共通）
 * 概要　　  : メンテナンス中に表示される画面（管理コンソール画面にてメンテナンスモードへ切り替え）
 */
@Component
export default class MLC_0900 extends Mixins(AppBase, StoreMixin) {
  iconMaintenance = filePath.iconMaintenance

  constructor() {
    super()
    this.showPrimaryHeader({ withNotificationMessage: false, withBackButton: false })
  }
}
