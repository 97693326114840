
import { Component, Vue } from 'vue-property-decorator'
import { globalEventBus } from '@/main'
import { fixVSelectKeyboardForIOS } from '@/utilities'
import SelectLanguage from '@/components/SelectLanguage.vue'
import customizeStyle from '../../../customize.json'

@Component({
  components: {
    SelectLanguage,
  },
})
export default class AppHeader extends Vue {
  // iconUser = filePath.iconAvatar
  primary = customizeStyle.header.primary
  secondary = customizeStyle.header.secondary

  constructor() {
    super()
  }

  mounted() {
    fixVSelectKeyboardForIOS()
  }

  handleBackButtonClick(): void {
    globalEventBus.$emit('navigate-to-previous-screen')
  }

  handleAppNameClick(): void {
    if (this.$route.name !== 'RootView') {
      this.$router.replace({ name: 'RootView' })
    } else {
      globalEventBus.$emit('app-name-click')
    }
  }
}
