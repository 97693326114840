
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { StoreMixin } from '@/mixins'

/**
 * 画面仕様書
 *
 * 機能名　  : ユーザ管理機能
 * 画面ＩＤ  : MTP-0400
 * 画面名　  : 登録情報削除完了画面
 * 概要　　  : 登録情報削除後の確認画面
 */
@Component
export default class MTP_0400 extends Mixins(StoreMixin) {
  constructor() {
    super()
  }

  created() {
    this.showPrimaryHeader({ withBackButton: false, withNotificationMessage: false })
  }
}
